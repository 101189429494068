.card_header {
  display: flex;
  justify-content: flex-end;
}
.create__quiz_modal_body {
  display: inline-block;
  width: 100%;
  padding: 0px !important;
  position: absolute;

  border: 1px solid white !important;
}
.textalign{

  text-align-last: center;
}
.table_headings{
  display: flex;
  justify-content: space-between;
}