.textalign{

    text-align-last: right;
}

.labels{

    padding-left: 13px !important;  
    padding-top: 5px !important;
}
.headlabel{
    text-align-last: right;


}

.second_container{
    display: flex;
    flex-direction: column;

}