.PDF_container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color:black;
}
.PDF_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    width: 100%;
    border-bottom: 1px solid #21bdbd;
    /* background-color: red; */
}
.PDF_header_zoom{
    display: flex;
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 20px;    
}
.MuiIconButton-root:hover{
    background-color:#2e68f6 !important;
    color:white !important;
}
.PDF_footer{
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 8px;
}
.PDF_footer_page{
    /* justify-self: center; */
    display: flex;
    margin-left: 20px;
}
.PDF_footer_page_no{
    display: flex;
    margin-right: 20px;
    align-items: center;
}

.PDF_footer_page_no > h2{
	font-weight: bold;
	position: relative;
	/* top:25px; */
	/* color: white; */
}

.PDF_footer_page_no > span{
	position: relative;
    left: 8px;
    font-weight: 100;
    font-size: 20px;
}
.fullscreen-enabled .my-component {
    background: #fff !important;
  }